import _ from "lodash";

_.mixin({int});
_.mixin({float});
_.mixin({intersects});
_.mixin({findElementPos});
_.mixin({uniSortBy});
_.mixin({hexToRgba});
_.mixin({translit});
_.mixin({getNumEnding});

const _associations = {
	"а": "a",
	"б": "b",
	"в": "v",
	"ґ": "g",
	"г": "g",
	"д": "d",
	"е": "e",
	"ё": "e",
	"є": "ye",
	"ж": "zh",
	"з": "z",
	"и": "i",
	"і": "i",
	"ї": "yi",
	"й": "i",
	"к": "k",
	"л": "l",
	"м": "m",
	"н": "n",
	"о": "o",
	"п": "p",
	"р": "r",
	"с": "s",
	"т": "t",
	"у": "u",
	"ф": "f",
	"х": "h",
	"ц": "c",
	"ч": "ch",
	"ш": "sh",
	"щ": "sh'",
	"ъ": "",
	"ы": "i",
	"ь": "",
	"э": "e",
	"ю": "yu",
	"я": "ya"
};

function translit(str, spaceReplacement) {
	if (!str) {
		return "";
	}

	let new_str = "";
	for (let i = 0; i < str.length; i++) {
		let strLowerCase = str[i].toLowerCase();
		if (strLowerCase === " " && spaceReplacement) {
			new_str += spaceReplacement;
			continue;
		}
		let new_letter = _associations[strLowerCase];
		if ("undefined" === typeof new_letter) {
			new_str += strLowerCase;
		}
		else {
			new_str += new_letter;
		}
	}
	return new_str;
}



const body = document.body;
/**
 *
 * @param elem
 * @returns {{left, top: (number|Number)}}
 */
export function findElementPos(elem) {
	let offsetLeft = elem.offsetLeft;
	let offsetTop = elem.offsetTop;
	let lastElem = elem;

	// eslint-disable-next-line no-param-reassign
	while (elem = elem.offsetParent) {
		if (elem === body) { // from my observation, document.body always has scrollLeft/scrollTop == 0
			break;
		}
		offsetLeft += elem.offsetLeft;
		offsetTop += elem.offsetTop - elem.scrollTop;
		lastElem = elem;
	}
	if (lastElem && lastElem.style.position === "fixed") { // slow - http://jsperf.com/offset-vs-getboundingclientrect/6
		// if(lastElem !== document.body) { //faster but does gives false positive in Firefox
		offsetLeft += window.pageXOffset || document.documentElement.scrollLeft;
		offsetTop += window.pageYOffset || document.documentElement.scrollTop;
	}

	return {
		left: offsetLeft,
		top: offsetTop
	};
}


/**
 *
 * @param value
 * @returns {Number}
 */
export function float(value) {
	if (typeof value === "number") { return parseFloat(value); }

	const newValue = (value || "").toString().replace(/,/, ".");

	return parseFloat(newValue);
}


/**
 *
 * @param value
 * @returns {Number}
 */
export function int(value) {
	return parseInt(value, 10);
}


/**
 *
 * @param collections
 * @returns {boolean}
 */
export function intersects(...collections) {
	const arrays = _.map(collections, array => _.isArray(array) ? array : [array]);
	const intersection = _.intersection.apply(this, arrays);

	return !_.isEmpty(intersection);
}


/**
 * Сортируем коллекцию объектов по полю типа String без учета регистра
 * @param collection {Array}
 * @param fieldName  {String}
 * @return {*|Array}
 */
export function uniSortBy(collection, fieldName) {
	return _.sortBy(collection, item => item[fieldName].toLowerCase().replace("ё", "е" + String.fromCharCode(1110)));
}


/**
 * Transform Hex to RGBA
 * hex   {String} required argument
 * alpha {Number} optional argument
 */
export function hexToRgba(hex, alpha = 1) {
	try {
		if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			throw new Error("Неверный формат HEX-color");
		}

		let c = hex.substring(1).split("");
		let a = alpha;

		if (alpha > 1) {
			a = 1;
		}

		if (alpha < 0) {
			a = 0;
		}

		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = `0x${c.join("")}`;

		return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},${a})`;
	} catch (err) {
		console.error(err);
	}
}


/**
 * Функция возвращает окончание для множественного числа слова на основании числа и массива окончаний
 * param  iNumber Integer Число на основе которого нужно сформировать окончание
 * param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
 *         например ['день', 'дня', 'дней']
 * return String
 */
export function getNumEnding(number, aEndings) {
	const iNumber = number % 100;
	let sEnding;
	let i;

	if (iNumber >= 11 && iNumber <= 19) {
		sEnding = aEndings[2];
	} else {
		i = iNumber % 10;
		switch (i) {
			case (1):
				sEnding = aEndings[0];
				break;
			case (2):
			case (3):
			case (4):
				sEnding = aEndings[1];
				break;
			default:
				sEnding = aEndings[2];
		}
	}

	return sEnding;
}


window._ = _;
